<template>
  <div>
    <b-overlay :show="false" rounded="sm">
    <b-card>
      <b-form class="mt-2" ref="formReport">       
        <input type="hidden" name="budget_id" v-model="budget_id">
        <!-- form -->
        <b-row>
          <!-- <b-col sm="12">
            <b-form-group label="TITULO DE PRESPUESTO" label-for="description">
              <b-form-textarea name="description" required />
            </b-form-group>
          </b-col> -->
            <!--TODO añadir más campos como tipo_iva, clausula y metodo de pago -->
          <b-col sm="4">
            <b-form-group label="Fecha" label-for="receipt_date">
              <b-form-input type="date" v-model="receipt_date" name="date"></b-form-input>
            </b-form-group>
          
          </b-col>
          <b-col sm="4">           
            <b-form-group label="Importe" label-for="amount">              
              <b-form-input type="number" v-model="amount" name="amount"></b-form-input>
            </b-form-group>
          </b-col>
          
          <b-col sm="4">           
            <b-form-group label="Comentario" label-for="description">
              <b-form-textarea name="description" v-model="description" />    
            </b-form-group>
          </b-col>
          <b-col sm="4">           
            <b-form-group label="Método de pago" label-for="payment">
              <v-select
                :options="payment_methods"                
                placeholder="Seleccione método"
                label="title"
                :getOptionKey="getOptionKey"
                v-model="method_id"
                name="payment_method_id"
              />           
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button variant="primary" class="mt-2 mr-1" type="button" @click="saveForm">
              Añadir cobro
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    </b-overlay>
  </div>
</template>

<script>
  import { BFormTextarea, BButton, BForm, BOverlay,BCardHeader, BCardBody, BFormGroup, BFormInput, BRow, BCol,  BCard,BCardText} from 'bootstrap-vue'  
  import vSelect from 'vue-select';
  export default {
    components : { BFormTextarea,vSelect, BButton, BForm, BOverlay,BCardHeader, BCardBody, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText,},
    data() {
      return {
        budget_id : this.$route.params.budget,
        receipt_date : new Date().toISOString().slice(0,10),
        internal_note: null,  
        description: null,
        payment_methods: [],       
        method_id: null, 
        amount: 0,
        task: null     
      }
    },
    created () {    
      this.$http.get("/api/getMethods").then((response) => {
        this.payment_methods = response.data;       
      })
      this.$http.get("/api/getAmountPending?id=" + this.budget_id).then((response) => {    
        this.amount = response.data
      });
   
   
      // this.$http.get("/api/getTask?id=" + this.task_id).then((response) => {
      //   this.task = response.data;

    },
    methods: {
      getOptionKey(option) {
        return option ? option.id : null;
      },
      saveForm : async function (e) {
        this.show = true;
        var formData = new FormData(this.$refs.formReport);   
        if (!this.method_id) {
          this.$bvToast.toast('ERROR : Método de pago es obligatorio', { variant: 'danger', noCloseButton: true });
          return
        }   
        formData.set('method_id', this.method_id ? this.method_id.id : null);
        console.log(formData);
        await this.$http({
          method: "post",
          url: "/api/insertReceipt",
          data: formData,
        }).then(response => {
          let id = response.data
          this.show = false;         
          this.$router.push("/report/"+id);
        }).catch(response => { window.console.log(response) });
        this.$bvToast.toast('Cobro generado con éxito', { variant: 'success', noCloseButton: true });
      }
    }
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>